import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import Box from "../../../../../components/box"
import Bullet from "../../../../../components/bullet"
import BulletList from "../../../../../components/bullet-list"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import Image from "../../../../../components/image"
import ImageHotspot from "../../../../../components/image-hotspot"
import ImageHotspots from "../../../../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../../../../components/image-hotspots-modal-template"
import Note from "../../../../../components/note"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "reiseerinnerung" })

  const data = useStaticQuery(graphql`
    query ReisepassQuery {
      reisepass: file(relativePath: { eq: "kurse/reisepass.png" }) {
        ...largeImage
      }
      reisepass01: file(relativePath: { eq: "kurse/reisepass-01.jpg" }) {
        ...largeImage
      }
      reisepass02: file(relativePath: { eq: "kurse/reisepass-02.jpg" }) {
        ...largeImage
      }
      reisepass03: file(relativePath: { eq: "kurse/reisepass-03.jpg" }) {
        ...largeImage
      }
      reisepass04: file(relativePath: { eq: "kurse/reisepass-04.jpg" }) {
        ...largeImage
      }
      reisepass05: file(relativePath: { eq: "kurse/reisepass-05.jpg" }) {
        ...largeImage
      }
      reisepass06: file(relativePath: { eq: "kurse/reisepass-06.jpg" }) {
        ...largeImage
      }
      reisepass07: file(relativePath: { eq: "kurse/reisepass-07.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/reiseerinnerung/01-suedfrankreich/gedicht-in-emojis" />
      }
    >
      <Seo title="Erforsche Hölderlins Rückreisepass" />
      <Stack>
        <Heading as="h2" level={2}>
          Erforsche Hölderlins Rückreisepass
        </Heading>
        <Paragraph>
          Lange hält es Hölderlin trotz des guten ersten Eindrucks (»Fast wohn'
          ich zu herrlich«) jedoch nicht in Bordeaux. Schon 3 1/2 Monate später
          packte er erneut den Reisekoffer und machte sich auf den Weg zurück
          nach Hause. Dafür musste er sich in Bordeaux einen Pass ausstellen
          lassen.
        </Paragraph>

        <TaskSidebar
          main={
            <Stack space={3}>
              <ImageHotspots
                caption="Hölderlins Reisepass aus Bordeaux; Württembergische Landesbibliothek Stuttgart, Cod.poet.et.phil.fol.63,V,b,5_1v"
                image={
                  <Image
                    image={data.reisepass.childImageSharp.gatsbyImageData}
                    alt="Hölderlins Reisepass aus Bordeaux"
                  />
                }
              >
                <ImageHotspot x={50} y={24} name="reisepass-01">
                  <ImageHotspotsModalTemplate
                    title="Das Datum"
                    image={
                      <Image
                        image={data.reisepass01.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Datierungen wurden in Frankreich zu dieser Zeit nach dem
                      französischen Revolutionskalender angegeben, der ausgehend
                      von der Ausrufung der Ersten Französischen Republik im
                      Jahr 1792 rechnete. So heißt es hier: »Bordeaux, le vingt
                      du mois de floral an dix de la republique Français, une et
                      indivisible.« (Bordeaux, den zwanzigsten des Monats
                      Floreal im Jahr zehn der einen, unteilbaren Französischen
                      Republik)
                    </Paragraph>
                    <Paragraph>
                      Tatsächlich wurde der Pass am 10. Mai 1802 von der
                      Polizeibehörde in Bordeaux ausgestellt. Unmittelbar nach
                      Erhalt des Passes muss Hölderlin aufgebrochen sein.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={64} y={42} name="reisepass-02">
                  <ImageHotspotsModalTemplate
                    title="Die Reiseroute"
                    image={
                      <Image
                        image={data.reisepass02.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Während Hölderlin auf der Hinreise von den Behörden in
                      Straßburg eine genaue Route vorgeschrieben bekam und seine
                      ursprünglichen Reisepläne entsprechend umplanen musste,
                      erlauben ihm diese vorgedruckten Worte auf der Rückreise
                      nun, »à laisser passer et librement circuler de Bordeaux à
                      Strasbourg« (frei zwischen Bordeaux und Straßburg zu
                      passieren). Damit konnte Hölderlin auch einen Umweg über
                      Paris einlegen und sich die französische Hauptstadt und
                      ihre Galerien ansehen.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={42} y={85} name="reisepass-03">
                  <ImageHotspotsModalTemplate
                    title="Der Passierstempel"
                    image={
                      <Image
                        image={data.reisepass03.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Der Stempel samt handgeschriebenem Zusatz bestätigen, dass
                      Hölderlin am 7. Juni 1802 (nach Rechnung des Französischen
                      Revolutionskalenders der 18. Prairial X) die Rheinbrücke
                      bei Kehl überquerte und damit nach Deutschland übersetzte.
                      Anders als auf der Hinreise, bei der er für die Strecke
                      von Nürtingen nach Straßburg gerade einmal 5 Tage
                      gebraucht hatte, erreichte er Stuttgart diesmal jedoch
                      erst Ende des Monats und, wie die Freunde berichteten, in
                      völlig verwahrlostem Zustand. Was in der Zeit dazwischen
                      passierte, beschäftigt die Hölderlin-Forscher noch heute.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={15} y={35} name="reisepass-04">
                  <ImageHotspotsModalTemplate
                    title="Die Personenbeschreibung"
                    image={
                      <Image
                        image={data.reisepass04.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Hölderlin wird im Pass wie folgt beschrieben:
                    </Paragraph>
                    <BulletList size={[3, 3, 4, 5]}>
                      <Bullet>Alter zweiunddreißig</Bullet>
                      <Bullet>(Deutscher)</Bullet>
                      <Bullet>Größe ein Meter 75 cm</Bullet>
                      <Bullet>Haare dunkelblond</Bullet>
                      <Bullet>Augenbrauen ebenso</Bullet>
                      <Bullet>Gesicht oval</Bullet>
                      <Bullet>Stirn hoch</Bullet>
                      <Bullet>Augen braun</Bullet>
                      <Bullet>Nase lang</Bullet>
                      <Bullet>Mund mittel</Bullet>
                      <Bullet>Kinn rund</Bullet>
                    </BulletList>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={75} y={47} name="reisepass-05">
                  <ImageHotspotsModalTemplate
                    title="Die Berufsbezeichnung"
                    image={
                      <Image
                        image={data.reisepass05.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Auf der Anreise hatte sich Hölderlin in Lyon als »homme de
                      lettre« (Schriftsteller) registrieren lassen. In seinem
                      Rückreisepass ist als »profession« nun »d'instituteur«
                      (Lehrer) vermerkt. Die beiden Dokumente, die keine 6
                      Monate auseinander liegen, veranschaulichen die beiden
                      Pole, zwischen denen Hölderlin stets schwankte: das Ideal
                      und die nüchterne Realität. Andererseits verstand sich
                      Hölderlin auch in seiner Rolle als Schriftsteller als eine
                      Art Erzieher der Menschheit.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
              </ImageHotspots>
            </Stack>
          }
          sidebar={
            <Note variant="task">
              <>
                Sieh dir Hölderlins Rückreisepass an und inspiziere die mit dem
                <Box
                  as="span"
                  display="inline-flex"
                  ml={1}
                  bg="primary"
                  color="background"
                  borderRadius="full"
                >
                  <Icon size={4} icon="plus" />
                </Box>{" "}
                Zeichen gekennzeichneten Details.
              </>
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
